.subscriptions-list-module {
    $subscription-list-module: &;
    background-color: var(--fog);
    padding: 80px 0;
    @media (max-width: 767px) {
        padding: 56px 0;

        &__header {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    @media (min-width: 1024px) {
        &__header--has-yearly {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 40px;
            #{$subscription-list-module}__title {
                text-align: left;
                max-width: 50vw;
                margin: 0;
            }
            #{$subscription-list-module}__subtitle {
                text-align: left;
            }
            #{$subscription-list-module}__subtitle {
                margin: 0.8em 0 0 0;
            }
        }
    }

    &__title,
    &__subtitle {
        text-align: center;
        margin: 0 auto 1em auto;
        max-width: 700px;
    }

    &__cta {
        text-align: center;
        margin: 3em 0;
    }

    &__yearly-toggler-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: 768px) {
            margin-left: auto;
        }
    }

    &__yearly-toggler {
        border: 1px solid var(--gray-60);
        border-radius: 100000px;
        display: inline-flex;
        padding: 8px;
        margin-bottom: 8px;
        cursor: pointer;

        > * {
            padding: 12px 24px;
            user-select: none;

            &:global(.active) {
                background-color: black;
                color: white;
                border-radius: 10000px;
            }
        }
    }
    &__yearly-disclaimer {
        color: var(--primary-orange);
        font-weight: 400;
        font-size: 12px;
        line-height: 1;
        padding: 7px 10px;
        border: 1px solid var(--primary-orange);
        border-radius: 2px;
        margin-bottom: 16px;
    }

    &__disclaimer {
        margin-top: 2em;
        font-size: 0.72em;
        color: var(--gray-100);
        line-height: 160%;
        font-weight: 300;
    }

    :global(> div > [class*='RibbonModule_ribbon-module']) {
        margin: 0.5em calc(-1 * var(--margin-spacing));
        padding-top: 1.25em;
        padding-bottom: 1.25em;
        :global(> * > [class*='RibbonModule_ribbon-module__static__inner']) {
            font-size: 1.35em;
        }
    }
}
